<template>
  <main>
    <h1 class="title">Offre Husqvarna Jambières 2022</h1>
    <div v-if="cpt1" class="counters">
      Il reste
      <div id="value"><span>1</span><span>0</span><span>0</span></div>
      participations disponibles 
    </div>    
    <div v-else style="width:100%;text-align:left;font-size:14px;color:red;padding-left:20px;">offre est désormais épuisée</div>


 
    <Form
      :class="'form_step' + step"
      id="monform"
      :form_id="form_id"
      :server="server"
      @on_error="viewError"
      :hideError="hiderror"
      v-on:results="getResult"
      v-on:step-up="checkStep"
      v-on:step-down="checkStep"
      v-on:form_submit_loading="loading"
      :relay="email"
    />
  </main>
</template>
<script>
var consts = require("../config");
import axios from "axios";

export default {
  name: "HOME",
  data: function() {
    return {
      cmpt430: undefined,
      cmpt450: undefined,
      cpt1 : true,
      hiderror: true,
      step: 0,
      email: undefined,
      form_id: consts.FORM_ID,
      server: consts.SERVER_URL,
    };
  },
  async mounted() {
    this.cmpt430 = await this.getCounterValue("Limite");

    if (this.cmpt430.data.value > 0) {
      const obj = document.getElementById("value");
      const current_counter = this.cmpt430.data.value;
      var output = [],
        sNumber = current_counter.toString();

      for (var i = 0, len = sNumber.length; i < len; i += 1) {
        output.push("<span>" + sNumber.charAt(i) + "</span>");
      }
      obj.innerHTML = output.join("");
      this.animateValue(obj, 800, this.cmpt430.data.value, 800);
    } else {
        this.cpt1 = false;    
    }

  },
  methods: {
    async getCounterValue(name) {
      return axios.get(
        "https://api.lucien.ai/api/v2/counters/" + consts.GAME_ID + "/" + name
      );
    },
    animateValue: function(obj, start, end, duration) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        var res = Math.floor(progress * (end - start) + start);
        var output = [],
          sNumber = res.toString();
   
        for (var i = 0, len = sNumber.length; i < len; i += 1) {
          output.push("<span>" + sNumber.charAt(i) + "</span>");
        }
        obj.innerHTML = output.join("");
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    },
    viewError(err) {
     
      if (err.indexOf("ip") > -1) {
        this.$swal({
          confirmButtonText: "OK",
          confirmButtonColor: "#C94330",
          title: "Désolé, mais vous avez déjà participé avec cette ip.",
          text: "",
        }).then(function() {
          window.location.href = "/";
        });
      } else {
        if (err[0] == "enseigne is required") err[0] = "Enseigne requise";
        this.$swal({
          type: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#C94330",
          text: err[0],
        }).then(function() {
          document.location.href = "/";
        });
      }
    },
    loading(value) {
      if (value === true) {
        this.$swal({
          title: "Envoi en cours",
          allowOutsideClick: false,
          html: "Merci de bien vouloir patienter ...",
          timerProgressBar: true,
          onBeforeOpen: () => {
            this.$swal.showLoading();
          },
        });
      }
    },
    checkStep() {
      this.step = this.step++;
    },
    getResult(result) {
      if (result._id) {
        this.$swal.close();
        this.$router.replace("/status/" + result._id + "/" + result.uid);
      } else {
        alert("une erreur est survenue pendant le processus");
      }
    },
  },
};
</script>
