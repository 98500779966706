<template>
  <header>

    <div
      v-if="
        this.$route.name === 'Status' ||
          this.$route.name === 'Upload' ||
          this.$route.name === 'Questionnaire'
      "
      class="header"
    >

        <img src="/hq-logo.png"
      />
    </div>
  </header>
</template>
<script>
export default {
  name: "HeaderElem",
  data() {
    return {
    };
  },
};
</script>
