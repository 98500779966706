<template>
  <footer>
  </footer>
</template>
<script>
var consts = require('../config');
export default {
    data() {
        return {
            game_id : consts.GAME_ID
        }
    }  
}
</script>